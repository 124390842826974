import React from "react";

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      fill="none"
      viewBox="0 0 45 45"
    >
      <path
        fill="#000"
        d="M12.191 19.87a27.802 27.802 0 0012.136 12.135l4.051-4.05a1.848 1.848 0 011.879-.461 20.923 20.923 0 006.574 1.05 1.842 1.842 0 011.842 1.84v6.446a1.841 1.841 0 01-1.842 1.842A31.306 31.306 0 015.525 7.365a1.842 1.842 0 011.841-1.841h6.446a1.841 1.841 0 011.841 1.841c0 2.302.368 4.512 1.05 6.575a1.848 1.848 0 01-.46 1.878L12.19 19.87z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
