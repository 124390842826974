import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CloseIcon } from "../../assets/Icons";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const navBarItems = [
    { lbl: "Home", slug: "#home" },
    {
      lbl: " About Us",
      slug: "#aboutus",
    },
    { lbl: "Landlords", slug: "#landlords" },
    { lbl: "Agents", slug: "#agents" },
    { lbl: "Tenants", slug: "#tenants" },
    { lbl: "Contact us", slug: "#contactus" },
  ];

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      setOpenSidebar(false);
    });
  }, []);

  return (
    <div
      className={`sidebar-s fixed rel anim ${openSidebar ? "show" : "hide"}`}
    >
      <div
        className={`side-block flex flex-col anim ${
          openSidebar ? "show" : "hide"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="hdr flex items-center justify-between">
          <div className="hdr-tag">
            <img src="./images/logo.svg" className="logo" />
          </div>
          <div
            className="icon-close flex aic jc"
            onClick={(e) => {
              setOpenSidebar(false);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div>
          <div className="menu-items flex aic flex-col">
            {navBarItems.map((item, index) => (
              <a
                href={item.slug}
                className={`item flex `}
                onClick={(e) => {
                  setOpenSidebar(false);
                }}
              >
                <div className="li">{item.lbl}</div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
