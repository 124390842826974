import React from "react";

function ServiceIcon1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="33"
      fill="none"
      viewBox="0 0 38 33"
    >
      <path
        fill="#414141"
        d="M36.5 27.875h-1.667v-13H36.5a.85.85 0 00.658-.308.804.804 0 00.15-.7l-3.333-13a.811.811 0 00-.296-.444.847.847 0 00-.513-.173H4.833a.848.848 0 00-.529.166A.811.811 0 004 .868l-3.334 13a.793.793 0 00.15.699.85.85 0 00.683.309h1.667v13H1.5a.844.844 0 00-.59.237.802.802 0 00-.244.575v3.25c0 .215.088.422.244.574a.845.845 0 00.59.238h35a.844.844 0 00.589-.238.802.802 0 00.244-.574v-3.25a.802.802 0 00-.244-.575.844.844 0 00-.59-.238zM4.59 13.486A.835.835 0 004 13.25H2.566L5.483 1.875h27.033l2.917 11.375H34a.844.844 0 00-.59.238.802.802 0 00-.244.575v13.812h-3.333V14.542l1.117.95 1.1-1.234-12.5-10.563a.846.846 0 00-1.1 0L5.95 14.258l1.1 1.235 1.116-.95v13.332H4.833V14.063a.795.795 0 00-.242-.577zm5.242-.35L19 5.387l9.166 7.75v14.74H9.833v-14.74zm25.833 17.99H2.333V29.5h33.333v1.625zM19 10.812a7.638 7.638 0 00-4.167 1.232 7.355 7.355 0 00-2.763 3.282 7.142 7.142 0 00-.426 4.225 7.258 7.258 0 002.052 3.744 7.561 7.561 0 003.84 2.001 7.678 7.678 0 004.334-.416 7.463 7.463 0 003.366-2.693 7.184 7.184 0 001.264-4.063c0-1.939-.79-3.799-2.197-5.17A7.6 7.6 0 0019 10.813zm0 13a5.94 5.94 0 01-3.241-.959 5.72 5.72 0 01-2.149-2.552 5.555 5.555 0 01-.332-3.286 5.645 5.645 0 011.597-2.912 5.882 5.882 0 012.986-1.557 5.973 5.973 0 013.37.324 5.804 5.804 0 012.619 2.095c.64.935.983 2.034.983 3.16a5.616 5.616 0 01-1.709 4.021A5.91 5.91 0 0119 23.813zm.833-8.938H21.5V16.5h-3.334v.813h2.5c.221 0 .433.085.59.238.156.152.244.359.244.574v2.438a.802.802 0 01-.244.574.845.845 0 01-.59.238h-.833v.813h-1.667v-.813H16.5V19.75h3.333v-.812h-2.5a.845.845 0 01-.59-.238.803.803 0 01-.243-.575v-2.437c0-.216.087-.422.244-.575a.844.844 0 01.589-.238h.833v-.812h1.667v.812z"
      ></path>
    </svg>
  );
}

export default ServiceIcon1;
