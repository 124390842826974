import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="30"
      fill="none"
      viewBox="0 0 38 30"
    >
      <path
        fill="#000"
        d="M37.543 4.05A3.694 3.694 0 0033.86.365H4.395A3.694 3.694 0 00.712 4.05v22.099a3.694 3.694 0 003.683 3.683H33.86a3.694 3.694 0 003.683-3.683V4.049zm-3.683 0l-14.732 9.207L4.395 4.05H33.86zm0 22.098H4.395V7.732l14.733 9.208L33.86 7.732v18.416z"
      ></path>
    </svg>
  );
}

export default MailIcon;
