import React from "react";
import {
  ServiceIcon1,
  ServiceIcon2,
  ServiceIcon3,
  ServiceIcon4,
  ServiceIcon5,
} from "../../assets/Icons";

const Services = () => {
  return (
    <div
      id="landlords"
      className="services-section flex bg-[#E3F7FF] border-t border-black/10 border-solid"
    >
      <div className="wrap wrapWidth flex flex-col items-center">
        <div className="flex flex-col sm:w-2/4 w-full justify-center items-center gap-3">
          <h1 className="sm:text-4xl text-2xl font-bold text-themeColor text-center">
            Landlords <span className="text-black">Services</span>
          </h1>
          <p className="text-black font-light text-sm text-center">
            We can provide you with Guaranteed, hassle-free rents for up to 5
            years, giving you complete peace of mind
          </p>
        </div>
        <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-center rounded-full border border-themeColor h-20 w-20 p-2">
              <div className="flex items-center justify-center bg-themeColor/40 h-full w-full rounded-full p-2">
                <ServiceIcon1 />
              </div>
            </div>
            <h1 className="text-[#212121] font-semibold text-sm mb-2">
              Guaranteed rents for Landlords no matter what the circumstances!
            </h1>
            <p className="text-black text-sm font-normal">
              We will give you this for the full duration of the contract.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-center rounded-full border border-themeColor h-20 w-20 p-2">
              <div className="flex items-center justify-center bg-themeColor/40 h-full w-full rounded-full p-2">
                <ServiceIcon2 />
              </div>
            </div>
            <h1 className="text-[#212121] font-semibold text-sm mb-2">
              We keep your property in prime condition!
            </h1>
            <p className="text-black text-sm font-normal">
              We will treat your property like our own, so were keen to keep
              your property in a move in condition.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-center rounded-full border border-themeColor h-20 w-20 p-2">
              <div className="flex items-center justify-center bg-themeColor/40 h-full w-full rounded-full p-2">
                <ServiceIcon3 />
              </div>
            </div>
            <h1 className="text-[#212121] font-semibold text-sm mb-2">
              Forget about tenant maintenance calls!
            </h1>
            <p className="text-black text-sm font-normal">
              We will be the point of contact for your tenants. We can also
              manage works and pay for minor works ourselves.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-center rounded-full border border-themeColor h-20 w-20 p-2">
              <div className="flex items-center justify-center bg-themeColor/40 h-full w-full rounded-full p-2">
                <ServiceIcon4 />
              </div>
            </div>
            <h1 className="text-[#212121] font-semibold text-sm mb-2">
              No more bills!
            </h1>
            <p className="text-black text-sm font-normal">
              We will take care of all the bills, so you don’t pay a penny
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-center rounded-full border border-themeColor h-20 w-20 p-2">
              <div className="flex items-center justify-center bg-themeColor/40 h-full w-full rounded-full p-2">
                <ServiceIcon5 />
              </div>
            </div>
            <h1 className="text-[#212121] font-semibold text-sm mb-2">
              No Fees!
            </h1>
            <p className="text-black text-sm font-normal">
              That’s right! We won’t charge you Landlord or management fees
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
