import React, { useEffect } from "react";
import Wrapper from "../../routes/Wrapper";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <div className="bg-gray-100 min-h-screen mt-10">
        <div className="container mx-auto py-12">
          <div className="max-w-3xl mx-auto bg-white p-8 shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p className="text-gray-800 mb-4 text-sm">
              Welcome to our website. We are committed to protecting your
              personal data and respecting your privacy. This privacy policy
              outlines the types of personal data we collect and how we use it.
            </p>
            <h1 className="text-xl font-bold my-6">Who we are.</h1>
            <p className="text-gray-800 mb-4 text-sm">
              We are Powerstone Property Solutions, and our website address is
              www.powerstonepropertysolutions.com
            </p>
            <h1 className="text-xl font-bold my-6">
              What personal data we collect and why we collect it
            </h1>
            <h3 className="text-base font-semibold my-3">Comments</h3>
            <p className="text-gray-800 mb-4 text-sm">
              When visitors leave comments on the site, we collect the data
              shown in the comments form, the visitor's IP address, and browser
              user agent string. We collect this information to help detect and
              prevent spam.
            </p>
            <h3 className="text-base font-semibold my-3">Cookies</h3>
            <p className="text-gray-800 mb-4 text-sm">
              If you leave a comment on our site, you may opt-in to saving your
              name, email address, and website in cookies. These cookies are for
              your convenience so that you do not have to fill in your details
              again when you leave another comment.
            </p>
            <br />
            <br />
            <p className="text-gray-800 mb-4 text-sm">
              We use cookies to collect information about your visit to our
              website, including the pages you viewed, the links you clicked,
              and the duration of your visit. We use this information to improve
              our website and to provide a better user experience.
            </p>
            <h3 className="text-base font-semibold my-3">
              Embedded content from other websites
            </h3>
            <p className="text-gray-800 mb-4 text-sm">
              Articles on this site may include embedded content, such as
              videos, images, and articles, which may behave in the same way as
              if the visitor has visited the other website. These websites may
              collect data about you, use cookies, embed additional third-party
              tracking, and monitor your interaction with that embedded content.
            </p>
            <h3 className="text-base font-semibold my-3">Analytics</h3>
            <p className="text-gray-800 mb-4 text-sm">
              We use Google Analytics to collect information about your visit to
              our website, including the pages you viewed, the links you
              clicked, and the duration of your visit. We use this information
              to improve our website and to provide a better user experience.
            </p>
            <h3 className="text-base font-semibold my-3">
              Information You Provide to Us
            </h3>
            <p className="text-gray-800 mb-4 text-sm">
              We collect the information you provide directly to us. For
              example, we collect information when you subscribe to a newsletter
              or email list, participate in an event, survey, contest, or
              promotion, communicate with us via third-party social media sites,
              provide information via a contact form, or otherwise communicate
              with us.
            </p>
            <h3 className="text-base font-semibold my-3">
              The types of information we may collect include your:
            </h3>
            <div className="flex flex-col gap-1">
              <p className="text-gray-800 text-sm">● Name</p>
              <p className="text-gray-800 text-sm">● Email address</p>
              <p className="text-gray-800 text-sm">● Postal address</p>
              <p className="text-gray-800 text-sm">● Phone number</p>
              <p className="text-gray-800 text-sm">
                ● Any other information you choose to provide
              </p>
            </div>
            <h3 className="text-base font-semibold my-3">Security</h3>
            <p className="text-gray-800 mb-4 text-sm">
              We take reasonable measures to help protect information about you
              from loss, theft, misuse, and unauthorized access, disclosure,
              alteration, and destruction.
            </p>
            <h3 className="text-base font-semibold my-3">Social sharing</h3>
            <p className="text-gray-800 mb-4 text-sm">
              This website may offer social sharing features and other
              integrated tools (such as the Facebook “Like” button), which let
              you share actions you take on our website with other media
              services. Your use of these tools enables the sharing of
              information with the public, depending on the settings you
              establish with your social networks.
            </p>
            <h3 className="text-base font-semibold my-3">
              Changes to our Privacy Policy
            </h3>
            <p className="text-gray-800 mb-4 text-sm">
              We reserve the right to modify this privacy policy at any time.
              Any changes will be reflected on this page.
            </p>
            <h3 className="text-base font-semibold my-3">Contact Us</h3>
            <p className="text-gray-800 mb-4 text-sm">
              If you have any questions about this privacy policy or our use of
              your personal data, please contact us at
              enquiries@powerstone-solutions.com
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PrivacyPolicy;
