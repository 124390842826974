import React from "react";
import { Link } from "react-router-dom";
import { MenuIcon } from "../../assets/Icons";

const Header = ({ setOpenSidebar }) => {
  return (
    <div className="header-camp flex bg-transparent">
      <div className="wrapWidth wrap flex items-center gap-5">
        <div className="left flex items-center">
          <Link to="/" className="logo-img flex items-center justify-center">
            <img src="../images/logo.svg" className="logo" />
          </Link>
        </div>
        <div className="right flex items-center justify-end sm:gap-8 gap-5">
          <div className="menu-list md:flex hidden items-center gap-8">
            <a href="#home" className="menu-item">
              Home
            </a>
            <a href="#aboutus" className="menu-item">
              About Us
            </a>
            <a href="#landlords" className="menu-item">
              Landlords
            </a>
            <a href="#agents" className="menu-item">
              Agents
            </a>
            <a href="#tenants" className="menu-item">
              Tenants
            </a>
            <a href="#contactus" className="menu-item">
              Contact us
            </a>
          </div>
          <div
            className="flex items-center justify-center cursor-pointer md:hidden"
            onClick={(e) => {
              e.stopPropagation();
              setOpenSidebar(true);
            }}
          >
            <MenuIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
