import React, { useEffect } from "react";
import "./css/App.scss";
import "./index.css";

import Routing from "./routes/Routing";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Routing />;
}

export default App;
