import React, { useState } from "react";
import { MailIcon, PhoneIcon } from "../../assets/Icons";
import axios from "axios";
import { useFormik } from "formik";
import { createFormValidation } from "../../utiles/formValidations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    selectedOptions: "",
    message: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    axios
      .post("https://salmon-codfish-cuff.cyclic.app/api/contactUsFrom", {
        values,
      })
      .then((response) => {
        console.log("Response:", response);
        if (response.status === 200) {
          toast.success(response?.data.message || "Data Send Successfully !", {
            position: "top-center",
          });
          resetForm();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something wrong try again later !", {
          position: "top-center",
        });
      })
      .finally(() => {});
  };
  const formik = useFormik({
    initialValues,
    validationSchema: createFormValidation,
    onSubmit,
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    setFieldValue,
    errors,
  } = formik;

  // Handler function to update the selected option
  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);

  //   if (option === "Landlord") {
  //     setFieldValue("landlord", "Yes");
  //     setFieldValue("tenant", "No");
  //     setFieldValue("agent", "No");
  //   } else if (option === "Tenant") {
  //     setFieldValue("tenant", "Yes");
  //     setFieldValue("landlord", "No");
  //     setFieldValue("agent", "No");
  //   } else if (option === "Agent") {
  //     setFieldValue("agent", "Yes");
  //     setFieldValue("tenant", "No");
  //     setFieldValue("landlord", "No");
  //   }
  // };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFieldValue("selectedOptions", option);
  };

  console.log("what is in values", values);
  console.log("what is in errors", errors);
  return (
    <div id="contactus" className="contact-us-section flex bg-white">
      <ToastContainer />
      <div className="wrap wrapWidth flex flex-col justify-center gap-10">
        <h1 className="text-2xl font-semibold text-center">Contact us</h1>
        <div className="_block flex">
          <div className="bg-img sm:flex hidden flex-[0.5]"></div>
          <div className="meta flex flex-1 flex-col sm:p-6 p-2">
            <h1 className="text-[#069EDA] sm:text-3xl text-lg font-semibold text-center">
              Better yet lets meet for a coffee!
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-6 mt-5 sm:px-8 px-1"
            >
              <div className="flex flex-col">
                <input
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isError={errors.name && formik.touched.name}
                  placeholder="Name"
                  className="bg-[#F6F6F6]/60 p-3 border border-black/30 rounded-lg"
                />
                {errors.name && formik.touched.name && (
                  <div className="text-red-500 text-sm">{errors.name}</div>
                )}
              </div>
              <div className="flex flex-col">
                <input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isError={errors.email && formik.touched.email}
                  placeholder="Email Address"
                  className="bg-[#F6F6F6]/60 p-3 border border-black/30 rounded-lg"
                />
                {errors.email && formik.touched.email && (
                  <div className="text-red-500 text-sm">{errors.email}</div>
                )}
              </div>

              <div className="flex flex-col">
                <input
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  isError={errors.phone && formik.touched.phone}
                  placeholder="Phone"
                  className="bg-[#F6F6F6]/60 p-3 border border-black/30 rounded-lg"
                />
                {errors.phone && formik.touched.phone && (
                  <div className="text-red-500 text-sm">{errors.phone}</div>
                )}
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-3">
                  <div
                    className={`flex items-center justify-center bg-[#F6F6F6]/60 p-3 border border-black/30 rounded-lg gap-2 flex-1 ${
                      selectedOption === "Landlord" ? "bg-green-200" : ""
                    }`}
                    onClick={() => handleOptionChange("Landlord")}
                  >
                    <input
                      type="radio"
                      name="selectedOptions"
                      className="h-5 w-5"
                      checked={selectedOption === "Landlord"}
                      readOnly
                    />
                    <p className="sm:text-sm text-[8px]">Landlord</p>
                  </div>
                  <div
                    className={`flex items-center justify-center bg-[#F6F6F6]/60 p-3 border border-black/30 rounded-lg gap-2 flex-1 ${
                      selectedOption === "Tenant" ? "bg-green-200" : ""
                    }`}
                    onClick={() => handleOptionChange("Tenant")}
                  >
                    <input
                      type="radio"
                      name="selectedOptions"
                      className="h-5 w-5"
                      checked={selectedOption === "Tenant"}
                      readOnly
                    />
                    <p className="sm:text-sm text-[8px]">Tenant</p>
                  </div>
                  <div
                    className={`flex items-center justify-center bg-[#F6F6F6]/60 p-3 border border-black/30 rounded-lg gap-2 flex-1 ${
                      selectedOption === "Agent" ? "bg-green-200" : ""
                    }`}
                    onClick={() => handleOptionChange("Agent")}
                  >
                    <input
                      type="radio"
                      name="selectedOptions"
                      className="h-5 w-5"
                      checked={selectedOption === "Agent"}
                      readOnly
                    />
                    <p className="sm:text-sm text-[8px]">Agent(tick box)</p>
                  </div>
                </div>
                {errors.selectedOptions ? (
                  <div className="text-red-500 text-sm">
                    {errors.selectedOptions}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col">
                <textarea
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  isError={errors.message && formik.touched.message}
                  placeholder="Your massage"
                  className="bg-[#F6F6F6]/60 p-3 min-h-[200px] border border-black/30 rounded-lg"
                />
                {errors.message && formik.touched.message && (
                  <div className="text-red-500 text-sm">{errors.message}</div>
                )}
              </div>

              <div className="flex items-center justify-center">
                <button className="btn bg-themeColor w-max !px-16">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <div className="flex items-center flex-1 gap-3">
            <div className="flex items-center justify-center h-4 w-4">
              <PhoneIcon />
            </div>
            <p className="sm:text-lg text-sm">0204 538 9338</p>
          </div>
          <div className="flex items-center flex-1 gap-3">
            <div className="flex items-center justify-center h-4 w-4">
              <MailIcon />
            </div>
            <p className="sm:text-lg text-sm">info@powerstone-solutions.com</p>
          </div>
        </div>
        <div className="flex items-center justify-center gap-8">
          <img src="./images/image1.png" className="sm:h-[80px] h-14" />
          <img src="./images/image2.png" className="sm:h-[80px] h-14" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
