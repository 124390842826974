import React from "react";

function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      fill="none"
      viewBox="0 0 24 18"
    >
      <path
        fill="#353535"
        d="M24 1.893L7.543 18 0 10.617l1.934-1.892L7.543 14.2 22.066 0 24 1.893z"
      ></path>
    </svg>
  );
}

export default TickIcon;
