import React from "react";

const Tenants = () => {
  return (
    <div id="tenants" className="tenants-section flex bg-[#E3F7FF]">
      <div className="wrap wrapWidth flex gap-10 sm:flex-row flex-col">
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex items-center gap-4">
            <div className="w-16 border-b-4 border-themeColor2 border-solid" />
            <h1 className="text-black font-normal text-2xl">Tenants</h1>
          </div>
          <h1 className="sm:text-4xl text-2xl font-bold">
            We <span className="text-themeColor">believe</span> in the tenant
            <br />
            <span className="text-themeColor2"> first approach</span>
          </h1>
          <p className="text-[#494949] font-light text-sm">
            This is and will continue to be at the heart of what we do. We
            strongly believe that our tenants should have a real opportunity to
            shape the service they receive from us.
            <br /> <br />
            We provide beautiful, clean, Coliving spaces perfect for working
            professionals, giving you the comfort you deserve.
            <br /> <br /> Our Coliving spaces come with all bills included,
            giving you that extra reassurance.
          </p>
          <a
            href="#contactus"
            className="btn-learn-more button w-max rounded-md !px-10 font-medium text-base"
          >
            Contact Us
          </a>
        </div>
        <div className="flex flex-[0.8]">
          <img src="../images/tenants.webp" className="aboutus-img h-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Tenants;
