import React, { useEffect } from "react";

import Wrapper from "../../routes/Wrapper";
import HeroSection from "./HeroSection";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Agents from "./Agents";
import Tenants from "./Tenants";
import ContactUs from "./ContactUs";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <div className="lading-page">
        <HeroSection />
        <AboutUs />
        <Services />
        <Agents />
        <Tenants />
        <ContactUs />
      </div>
    </Wrapper>
  );
};

export default Main;
