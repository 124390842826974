import React from "react";

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      fill="none"
      viewBox="0 0 22 21"
    >
      <path
        fill="#000"
        d="M0.22 18.22H26.125V21.921H0.22z"
        transform="rotate(-44.696 .22 18.22)"
      ></path>
      <path
        fill="#000"
        d="M2.534 0.267H28.439V3.968H2.534z"
        transform="rotate(43.221 2.534 .267)"
      ></path>
    </svg>
  );
}

export default CloseIcon;
