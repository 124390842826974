import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Mousewheel, Pagination, Autoplay } from "swiper/modules";

const HeroSection = () => {
  const list = [
    { bgImage: "./images/home-bg1.png" },
    { bgImage: "./images/home-bg2.png" },
    { bgImage: "./images/home-bg3.png" },
    { bgImage: "./images/home-bg4.jpg" },
  ];

  const handleReachEnd = () => {
    // Find the next section you want to scroll to
    const nextSection = document.getElementById("aboutus");
    if (nextSection) {
      // Calculate the offset of the next section from the top of the page
      const offsetTop = nextSection.offsetTop;
      // Delay the scroll action by 3 seconds
      setTimeout(() => {
        // Scroll to the next section with smooth behavior after 3 seconds
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }, 3000); // Delay for 3 seconds (3000 milliseconds)
    }
  };

  const MetaFun = () => {
    return (
      <div className="wrap wrapWidth flex flex-col justify-center items-start">
        <div className="flex flex-col z-20 sm:gap-4 gap-3">
          <h1 className="text-white font-semibold sm:text-4xl text-3xl text-start">
            Specialist <span className="text-themeColor">HMO</span>
          </h1>
          <h1 className="text-themeColor2 font-semibold sm:text-4xl text-3xl text-start">
            Guaranteed Rent <span className="text-white">provider</span>
          </h1>
          <a
            href="#contactus"
            className="btn bg-themeColor text-white w-max py-[8px] text-sm"
          >
            Get in touch
          </a>
        </div>
      </div>
    );
  };
  return (
    <div id="home" className="hero-section bg-themeColor ">
      {/* For Desktop */}
      <div className="md:flex hidden">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={0}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          // onReachEnd={handleReachEnd}
          modules={[Mousewheel, Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{ delay: 9000 }}
        >
          {list.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="home-slider flex relative"
                style={{ backgroundImage: `url(${item.bgImage})` }}
              >
                <div className="overlay absolute inset-0 bg-black/60" />
                <MetaFun />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* For Mobile & tablet */}
      <div className="flex sm:hidden">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={0}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          onReachEnd={handleReachEnd}
          modules={[Mousewheel, Pagination]}
          className="mySwiper"
          // autoplay={{ delay: isDesktop ? 3000 : 60000 }}
        >
          {list.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="home-slider flex relative"
                style={{ backgroundImage: `url(${item.bgImage})` }}
              >
                <div className="overlay absolute inset-0 bg-black/60" />
                <MetaFun />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HeroSection;
