import React from "react";
import { Link } from "react-router-dom";
import { LocationIcon } from "../../assets/Icons";

const Footer = () => {
  return (
    <div className="footer-comp flex flex-col bg-[#424242] gap-5">
      <div className="f-top flex items-center sm:py-0 py-4">
        <div className="wrap wrapWidth flex items-center gap-8 sm:flex-row flex-col">
          <div className="flex flex-1 gap-5 sm:flex-row flex-col">
            <div className="flex flex-col justify-center gap-6">
              <h1 className="text-white text-xl">Contact Us</h1>
              <div className="flex gap-4">
                <div className="flex items-center justify-center h-4 w-4">
                  <LocationIcon />
                </div>
                <p className="text-white text-sm font-light">
                  Powerstone Property Solutions trading as Powerstone Solutions.
                  Registered office 3rd floor 86-90 Paul Street, London EC2A 4NE
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <img src="./images/logo.svg" className="h-[160px]" />
            </div>
          </div>
          <div className="flex sm:flex-[0.8] w-full flex-col sm:items-end items-start">
            <div className="flex flex-col  gap-6">
              <h1 className="text-white text-xl">Contact Us</h1>
              <Link
                to="/privacy-policy"
                className="text-white text-sm font-light"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="f-btm flex items-center justify-center border-t border-white/50 py-3">
        <p className="text-white sm:text-sm text-xs font-light text-center">
          Copyright: © 2024{" "}
          <span className=" font-medium">Powerstone Property Solutions.</span>{" "}
          All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
