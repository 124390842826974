import React from "react";

const AboutUs = () => {
  return (
    <div id="aboutus" className="about-us-section flex bg-white">
      <div className="wrap wrapWidth flex gap-10 sm:flex-row flex-col">
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex items-center gap-4">
            <div className="w-16 border-b-4 border-themeColor2 border-solid" />
            <h1 className="text-black font-normal text-2xl">About Us</h1>
          </div>
          <h1 className="sm:text-4xl text-2xl font-bold">
            We do the work so{" "}
            <span className="text-themeColor2">you don’t</span> have to
          </h1>
          <p className="text-[#494949] font-light text-sm">
            At Powerstone, we provide a quality service by delivering a fully
            hands off solution for Landlords who want their HMO’S to thrive and
            really work for them.
            <br /> <br /> Our innovative Solutions allow Landlords to sit back
            and see guaranteed rents coming in every month, year in year out.{" "}
            <br /> <br /> We believe that this provides lifelong well-being and
            consistency for both landlords and tenants alike, giving them both
            piece of mind as well as forming meaningful relationships.
          </p>
          <a
            href="#landlords"
            className="btn-learn-more button w-max rounded-md !px-10 font-medium text-base"
          >
            Learn More
          </a>
        </div>
        <div className="flex flex-[0.8]">
          <img src="../images/aboutus.webp" className="aboutus-img h-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
