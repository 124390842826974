import React from "react";
import { TickIcon } from "../../assets/Icons";

const Agents = () => {
  return (
    <div id="agents" className="agents-section flex bg-white">
      <div className="wrap wrapWidth flex gap-10 sm:flex-row flex-col">
        <div className="flex flex-[0.8]">
          <img src="../images/agents.webp" className="aboutus-img h-[460px]" />
        </div>
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex items-center gap-4">
            <div className="w-16 border-b-4 border-themeColor2 border-solid" />
            <h1 className="text-black font-normal text-2xl">Agents</h1>
          </div>
          <h1 className="sm:text-4xl text-2xl font-semibold">
            You are <span className="text-themeColor">valuable to us</span>, so
            let’s
            <span className="text-themeColor2"> work together!</span>
          </h1>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center h-4 w-4">
                <TickIcon />
              </div>
              <p className="text-[#494949] font-light text-sm">
                We will guarantee your fees
              </p>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center h-4 w-4">
                <TickIcon />
              </div>
              <p className="text-[#494949] font-light text-sm">
                We can strengthen your relationships with your landlords
              </p>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center h-4 w-4">
                <TickIcon />
              </div>
              <p className="text-[#494949] font-light text-sm">
                We can use you to find our professional tenants
              </p>
            </div>
          </div>
          <h1 className="text-2xl font-semibold">
            Why
            <span className="text-themeColor2"> work with </span>us?
          </h1>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center h-4 w-4">
                <TickIcon />
              </div>
              <p className="text-[#494949] font-light text-sm">
                We are HMO specialist.
              </p>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center h-4 w-4">
                <TickIcon />
              </div>
              <p className="text-[#494949] font-light text-sm">
                We are registered with the property redress scheme and the
                information commissioner’s office (ICO)
              </p>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center h-4 w-4">
                <TickIcon />
              </div>
              <p className="text-[#494949] font-light text-sm">
                We are members of the NRLA.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agents;
