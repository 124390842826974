import * as Yup from "yup";

export const createFormValidation = Yup.object({
  name: Yup.string().required("Please enter your full name."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter a valid email address."),
  phone: Yup.number()
    .required("Please enter your phone Number.")
    .positive("Please enter your phone Number"),
  selectedOptions: Yup.string().required("Please select one option"),
  // landlord: Yup.string()
  //   .oneOf(["Yes"], "Please select one option")
  //   .required("Please select one option"),
  // tenant: Yup.string()
  //   .oneOf(["Yes"], "Please select one option")
  //   .required("Please select one option"),
  // agent: Yup.string()
  //   .oneOf(["Yes"], "Please select one option")
  //   .required("Please select one option"),
  message: Yup.string().required("Please enter something in the message"),
});
